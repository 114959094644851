import './simenstad-style.scss';
import text from './text.json';
import {TextBox} from './components/textBox';
import {Header} from './components/header';
import {Footer} from './components/footer'

const imagesDir = "/images/"

//TODO split in to header and content.
function homeContent() {

  return (
    <>
      <Header/>

      <div className="main-container">

        <img src={imagesDir + "Edvin_headshot.jpg"} alt="Head shot of Edvin Simenstad" className="headshot" />

        <TextBox paragraphs={text.bio.split("\n")}></TextBox>

      </div>

      <Footer/>
    </>
  );
}

export default homeContent;
