import { MdEmail, MdPhoneAndroid } from "react-icons/md";
import { FaMapMarkerAlt, FaYoutube } from "react-icons/fa";
import { RiShoppingCartFill } from "react-icons/ri";
import { ReactComponent as LHElogo } from "./assets/LHE_logo.svg";

export const Footer = () => {

    var iconSize = 50;

    return (
        <div className="footer">
            <div className="footer-content">
                
                <div className="footer-box contact">
                    <h2>Kontakt:</h2>

                    <a href="mailto: edvin@simenstad.com">
                        <MdEmail size={iconSize}/>
                        Email: edvin@simenstad.com
                    </a>

                    <a href="tel:+47454600153">
                        <MdPhoneAndroid size={iconSize}/>
                        Tel: +47 454600153
                    </a>

                    <a href="https://maps.app.goo.gl/wnja6D5DG1tP2W5o7"><FaMapMarkerAlt size={iconSize}/>Oslo</a>
                </div>

                <div className="footer-box links">
                    <h2>Lenker:</h2>
                    <a href="https://www.sheetmusicplus.com/en/category/arrangeme/265/edvin-a.-simenstad/"><RiShoppingCartFill size={iconSize}/></a>
                    <a href="https://www.youtube.com/@edvinsimenstad7344"><FaYoutube size={iconSize}/></a>
                    <a href="https://www.lillestromharmoniensemble.com/"><LHElogo className="logo"/></a>
                </div>
            </div>
        </div>
    )
}