import { Squash as HamburgerMenu } from "hamburger-react";

export const Header = () => {
    return (
    <div className="header">
        <div className="content">
            <div className="menu-wrapper">
                {/* <HamburgerMenu/> TODO*/}
            </div>

            <h1 className="title"><a href="https://simenstad.com/">Edvin Simenstad</a></h1>

        </div>
    </div>
    )
}
